<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input v-model="search.account" placeholder="请输入账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">API：</span>
        <el-input v-model="search.api" placeholder="请输入api" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <el-date-picker v-model="search.searchDate" value-format="YYYY-MM-DD HH:mm:ss" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" ></el-date-picker>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="username" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="role_name" label="角色" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="api" label="api" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="ip" label="IP" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="addtime" label="操作时间" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{$common.timeToDate(scope.row.addtime)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="详情" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span @dblclick="copy(scope.row.content)">{{scope.row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="user-agent" label="user-agent" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span @dblclick="copy(scope.row['user-agent'])">{{scope.row['user-agent']}}</span>
        </template>
      </el-table-column>

    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "adminLog",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
        account: "", //管理员账号
        api: "", //api
        searchDate:[],
      },
      tableData: [], //
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取管理员数据
    getList() {
      console.log(this.search.searchDate)
      var bdate,edate
      if (this.search.searchDate && this.search.searchDate.length > 0){
        bdate = this.search.searchDate[0]
        edate = this.search.searchDate[1]
      }
      httpClient("adminLog").post({
        pageid:this.pager.page,
        pcount:this.pager.rows,
        api: this.search.api,
        account:this.search.account,
        bdate:bdate,edate:edate,
      }).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1) {
            this.pager.total = res.data.total
          }
        }
      })
    },
    copy(str) {
      var url = str;
      var oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({ message: "复制成功", type: "success" });
    },
  },
};
</script>


<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>
